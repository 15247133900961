import React, { useEffect, useReducer, useState } from "react";
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import ConversationModal from "../../components/ConversationModal";
import ConversationGenerateModal from "../../components/ConversationGenerateModal";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_MESSAGES") {
        const messages = action.payload;
        const newMessages = [];
    
        messages.forEach((message) => {
        const messageIndex = state.findIndex((m) => m.id === message.id);
        if (messageIndex !== -1) {
            state[messageIndex] = message;
        } else {
            newMessages.push(message);
        }
        });
    
        return [...newMessages, ...state];
    }
    
    if (action.type === "ADD_MESSAGE") {
        const newMessage = action.payload;
        const messageIndex = state.findIndex((m) => m.id === newMessage.id);
    
        if (messageIndex !== -1) {
        state[messageIndex] = newMessage;
        } else {
        state.push(newMessage);
        }
    
        return [...state];
    }

    if (action.type === "RESET") {
      return [];
    }
};

const Conversations = () => {
    const classes = useStyles();

    const [messagesList, dispatch] = useReducer(reducer, []);
    const [searchParam, setSearchParam] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [dataCount, setDataCount] = useState(false);
    const [loading, setLoading] = useState(false);
    const [conversationModalOpen, setConversationModalOpen] = useState(false);
    const [generateModalOpen, setGenerateModalOpen] = useState(false);
  
    useEffect(() => {
      dispatch({ type: "RESET" });
      setPageNumber(0);
    }, [searchParam]);
  
    useEffect(() => {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        const fetchMessages = async () => {
          try {
            const { data } = await api.get("/botconversations", {
              params: { searchParam, rowsPerPage, pageNumber: pageNumber+1 },
            });
  
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setDataCount(data.count);
            setLoading(false);
          } catch (err) {
            setLoading(false);
            toastError(err);
          }
        };
        fetchMessages();
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }, [searchParam, rowsPerPage, pageNumber]);

    const handleSearch = (event) => {
      setSearchParam(event.target.value.toLowerCase());
    };

    const handleChangePage = (event, newPage) => {
        dispatch({ type: "RESET" });
        setPageNumber(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch({ type: "RESET" });
        setRowsPerPage(parseInt(event.target.value, 10));
        setPageNumber(0);
    };

    const handleOpenConversationModal = () => {
        setConversationModalOpen(true);
    };
    
      const handleCloseConversationModal = () => {
        setConversationModalOpen(false);
    };

    const handleOpenGenerateModal = () => {
        setGenerateModalOpen(true);
    };

      const handleCloseGenerateModal = () => {
        setGenerateModalOpen(false);
    };

  return (
    <MainContainer>
      <ConversationModal
        open={conversationModalOpen}
        onClose={handleCloseConversationModal}
      />
      <ConversationGenerateModal
        open={generateModalOpen}
        onClose={handleCloseGenerateModal}
      />
      <MainHeader>
        <Title>{i18n.t("conversation.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenConversationModal}
          >
            {i18n.t("conversation.buttons.add")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenGenerateModal}
          >
            {i18n.t("conversation.buttons.generate")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("conversation.table.conversationId")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("conversation.table.botToken")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("conversation.table.destination")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("conversation.table.message")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("conversation.table.sent")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("conversation.table.scheduled")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("conversation.table.isSent")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {messagesList.map((message) => (
                <TableRow key={message.id}>
                  <TableCell align="center">{message.conversationId}</TableCell>
                  <TableCell align="center">{message.botToken}</TableCell>
                  <TableCell align="center">{message.destination}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {message.content}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">{message.timestamp}</TableCell>
                  <TableCell align="center">{message.isScheduled.toString()}</TableCell>
                  <TableCell align="center">{message.isSent.toString()}</TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
        <TablePagination
            component="div"
            count={dataCount}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Paper>
    </MainContainer>
  );
};

export default Conversations;
