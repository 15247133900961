import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textConversationContainer: {
    width: "100%",
  },
}));

const ConversationSchema = Yup.object().shape({
    messages: Yup.string()
        .required("Required"),
    destination: Yup.string()
        .required("Required"),
    tokensMap: Yup.string()
        .required("Required"),
});

const ConversationModal = ({
  open,
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    messages: "",
    destination: "",
    tokensMap: "",
  };

  const [conversation, setConversation] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    onClose();
    setConversation(initialState);
  };

  const handleSaveQuickAnswer = async (values) => {
    try {
    const { data } = await api.post("/botconversations", values);
    if (onSave) {
        onSave(data);
    }
    handleClose();
      toast.success(i18n.t("conversationModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
            {i18n.t("conversationModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={conversation}
          enableReinitialize={true}
          validationSchema={ConversationSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQuickAnswer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textConversationContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("conversationModal.form.messages")}
                    name="messages"
                    error={touched.messages && Boolean(errors.messages)}
                    helperText={touched.messages && errors.messages}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                <div className={classes.textConversationContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("conversationModal.form.destination")}
                    name="destination"
                    autoFocus
                    error={touched.destination && Boolean(errors.destination)}
                    helperText={touched.destination && errors.destination}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                <div className={classes.textConversationContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("conversationModal.form.tokensMap")}
                    name="tokensMap"
                    error={touched.tokensMap && Boolean(errors.tokensMap)}
                    helperText={touched.tokensMap && errors.tokensMap}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("conversationModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {i18n.t("conversationModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ConversationModal;
